<template>
  <mdb-container>
    <mdb-input class="mr-sm-2" type="text" placeholder="Search Taskqueue" aria-label="taskqueue_id" v-model="searchQuery" @keyup.enter.native="checkforenterpressed" />
    <i class="fa fa-search" style="cursor: pointer;" @click="goToSearch"></i>
    <mdb-modal v-if="showmodal" :show="showmodal" @close="showmodal = false" info size="lg">
      <mdb-modal-header>
        <mdb-modal-title>Taskqueues with name: {{searchQuery}}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body class="p-0">
        <mdb-spinner small color="blue" v-if="isloading===true" />
        <mdb-tbl v-if="isloading===false && taskqueues.length>0" responsive>
          <mdb-tbl-head>
            <tr>
              <th style="font-weight: bold;">Taskqueue #</th>
              <th style="font-weight: bold;">Queuename</th>
              <th style="font-weight: bold;">Project</th>
              <th style="font-weight: bold;">Added at</th>
            </tr>
          </mdb-tbl-head>
          <mdb-tbl-body>
            <tr v-for="taskqueue in taskqueues" :key="taskqueue.id" v-on:click="loadOrderDetails(taskqueue.id)" style="cursor: pointer;">
              <td>{{taskqueue.id}}</td>
              <td><span v-if="taskqueue.queuename!==null">{{taskqueue.queuename}}</span></td>
              <td>{{taskqueue.project}}</td>
              <td>{{taskqueue.added_at}}</td>
            </tr>
          </mdb-tbl-body>
        </mdb-tbl>
      </mdb-modal-body>
    </mdb-modal>
  </mdb-container>
</template>

<script>
import {
  mdbInput,
  mdbContainer,
  mdbModal,
  mdbSpinner,
  mdbTbl,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbTblHead,
  mdbTblBody,
} from 'mdbvue';

import Apicall from '@/libs/Apicall';

export default {
  name: 'SearchTasksQueueIdNavItem',
  data() {
    return {
      searchQuery: null,
      showmodal: false,
      isloading: true,
      error: null,
      taskqueues: [],
    };
  },
  components: {
    mdbInput,
    mdbContainer,
    mdbModal,
    mdbSpinner,
    mdbTbl,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbTblHead,
    mdbTblBody,
  },
  watch: {
    $route(to, from) {
      this.$router.go(to);
    },
  },
  methods: {
    goToTaskQueue() {
      this.$router.push({ name: 'EditTaskQueue', params: { taskqueueid: this.searchQuery } });
    },
    checkforenterpressed(e) {
        if (e.keyCode === 13) {
            this.goToSearch();
        }
    },
    goToSearch() {
      const apiCall = new Apicall();
      let taskqueue = null;
      let itemid = null;

      apiCall
        .call('GET', `api/task/search-taskqueue?keyword=${this.searchQuery}`, null)
        .then((response) => {
          if (response.status === 'OK') {
            this.taskqueues = response.data.taskqueues;
            switch (this.taskqueues.length) {
                  case 0:
                    this.notificationError(response.msg);
                    break;
                  case 1:
                    [taskqueue] = this.taskqueues;
                    if (taskqueue.id !== null) {
                      itemid = taskqueue.id;
                      this.$router.push({ name: 'EditTaskQueue', params: { taskqueueid: itemid } });
                    }
                    break;
                  default:
                    this.showmodal = true;
                    this.isloading = false;
                    break;
                }
          } else {
            this.notificationError(response.msg);
          }
        }).catch((response) => {
            this.notificationError(response.msg);
        });

      return false;
  },
  loadOrderDetails(taskqueueId) {
    this.showmodal = false;
    this.$router.push({ name: 'EditTaskQueue', params: { taskqueueid: taskqueueId } });
  },
  notificationError(_messsage) {
    this.$notify.error({ message: _messsage, position: 'top right', timeOut: 5000 });
  },
  },
};
</script>

<style scoped>
li.nav-item.ripple-parent.active {
  background-color: palegreen;
}
</style>
