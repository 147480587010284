<template>
  <div>
    <mdb-navbar color="blue-grey lighten-4" light>
      <mdb-navbar-brand tag="a" @click.native="gotohome" waves>
        <img src="/images/PLUSCREW-LOGO.png" style="width: 100px;" />
      </mdb-navbar-brand>
      <mdb-navbar-toggler>
        <mdb-navbar-nav right>
          <SearchTasksQueueIdNavItem v-if="this.$store.state.accesslevel === 'FULLACCESS'"></SearchTasksQueueIdNavItem>
          <mdb-dropdown tag="li" class="nav-item" v-if="$store.state.userisloggued" >
            <mdb-dropdown-toggle tag="a" navLink color="transparent" slot="toggle" waves-fixed>{{this.$store.state.firstname}} {{this.$store.state.lastname}} </mdb-dropdown-toggle>
            <mdb-dropdown-menu>
              <mdb-dropdown-item v-on:click="accessadmin" v-if="this.$store.state.accesslevel === 'FULLACCESS'">Administration</mdb-dropdown-item>
              <mdb-dropdown-item v-bind:href="'/userdetails'">User Details</mdb-dropdown-item>
              <mdb-dropdown-item :class="activeClass('Preferences')" v-on:click="accesspreferencescreen">Tasks History</mdb-dropdown-item>
              <mdb-dropdown-item v-bind:href="'/dashboard'">Dashboard</mdb-dropdown-item>
              <mdb-dropdown-item v-bind:href="'/tests'">Tests</mdb-dropdown-item>
              <mdb-dropdown-item v-on:click="logout">Logout</mdb-dropdown-item>
            </mdb-dropdown-menu>
          </mdb-dropdown>
        </mdb-navbar-nav>
      </mdb-navbar-toggler>
    </mdb-navbar>
  </div>
</template>

<script>
import {
  mdbNavbar, mdbNavbarBrand, mdbNavbarNav, mdbNavbarToggler, mdbDropdown, mdbDropdownToggle, mdbDropdownMenu, mdbDropdownItem,
} from 'mdbvue';
import SearchTasksQueueIdNavItem from './SearchTasksQueueIdNavItem.vue';

import Auth from '../libs/Auth';

export default {
  name: 'Header',
  data() {
    return {
      dummy: null,
    };
  },
  components: {
    mdbNavbar,
    mdbNavbarBrand,
    mdbNavbarNav,
    mdbNavbarToggler,
    mdbDropdown,
    mdbDropdownToggle,
    mdbDropdownMenu,
    mdbDropdownItem,
    SearchTasksQueueIdNavItem,
  },
  methods: {
    logout() {
      const auth = new Auth();
      auth.logout();
      this.$router.push({ name: 'Login' });
    },
    gotohome() {
      if (this.$store.state.accesslevel === 'FULLACCESS') {
        this.$router.push({ name: 'Admin' });
      } else {
        this.$router.push({ name: 'Dashboard' });
      }
    },
    accesspreferencescreen() {
      this.$router.push({ name: 'Preferences' });
    },
    openNewTab(_href) {
      window.open(_href, '_blank');
    },
    activeClass(_routename) {
      return _routename === this.$route.name ? 'active' : '';
    },
    accessadmin() {
      this.$router.push({ name: 'Admin' });
    },
  },
};
</script>

<style scoped>
li.nav-item.ripple-parent.active {
  background-color: palegreen;
}
</style>
