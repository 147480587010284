<template>
  <mdb-row>
    <mdb-col>
      <mdb-card>
        <mdb-card-header color="blue-grey darken-1">
          <mdb-row class="mb-1">
            <mdb-col>Task History <span v-if="employee">({{employee.email}})</span></mdb-col>
            <div class="ml-auto mr-4" v-if="employeeId !== null">
              Payment Due : {{ totalpaymentdue }} US$
            </div>
            <div class="ml-auto mr-4" v-else>
              <mdb-spinner multicolor size="sm" />
            </div>
          </mdb-row>
        </mdb-card-header>
        <mdb-card-body>
          <div>
            <task-history-table
              :task-history="taskHistory"
              v-if="employeeId !== null"
            />
            <mdb-spinner multicolor size="lg" v-else />
          </div>
        </mdb-card-body>
      </mdb-card>
    </mdb-col>
  </mdb-row>
</template>

<script>
import {
  mdbRow,
  mdbSpinner,
  mdbCol,
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
} from 'mdbvue';
import TaskHistoryTable from '@/components/TaskHistoryTable.vue';
import Apicall from '@/libs/Apicall';

export default {
  components: {
    mdbRow,
    mdbSpinner,
    mdbCol,
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    TaskHistoryTable,
  },

  props: {
    employeeId: null,
    includeEmployeeDetails: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      infos: null,
      error: null,
      employee: null,
      taskHistory: null,
      showtaskinfos: false,
      tasktodisplayinfos: {},
      totalpaymentdue: 0,
    };
  },
  mounted() {
    this.loadTaskHistory();
    this.loadTotalPaymentDue();

    if (this.includeEmployeeDetails) {
      this.loadEmployeeDetails();
    }
  },
  methods: {
    loadEmployeeDetails() {
      const api = new Apicall();

      const urlParams = new URLSearchParams();
      urlParams.append('employeeid', this.employeeId);

      api
        .call('POST', 'api/adminemployee/get-one', { employeeid: this.employeeId })
        .then((response) => {
          if (response.status === 'OK') {
            this.employee = response.data;
          } else {
            this.error = response.msg;
          }
        });
    },
    loadTaskHistory() {
      const self = this;
      const api = new Apicall();
      self.error = '';
      const urlParams = new URLSearchParams();
      urlParams.append('employeeid', this.employeeId);
      api
        .call('GET', `api/task/employee-task-history?${urlParams.toString()}`)
        .then((response) => {
          if (response.status === 'OK') {
            self.taskHistory = response.data;
          } else {
            self.error = response.msg;
          }
        });
    },
    loadTotalPaymentDue() {
      const self = this;
      const api = new Apicall();
      self.error = '';
      const urlParams = new URLSearchParams();
      urlParams.append('employeeid', this.employeeId);
      api
        .call(
          'GET',
          `api/task/employee-total-payment-due?${urlParams.toString()}`,
        )
        .then((response) => {
          if (response.status === 'OK') {
            self.totalpaymentdue = response.data;
          } else {
            self.error = response.msg;
          }
        });
    },
    async showTaskTodoInfos(_taskid) {
      const self = this;
      const api = new Apicall();
      self.error = '';
      self.showtaskinfos = false;
      await this.updateTaskDeliveryContent(_taskid);
      api
        .call('POST', 'api/task/infos', { taskid: _taskid })
        .then((response) => {
          if (response.status === 'OK') {
            self.showtaskinfos = true;
            self.tasktodisplayinfos = response.data;
          } else {
            self.error = response.msg;
          }
        });
    },
    async updateTaskDeliveryContent(_taskid) {
      const self = this;
      const api = new Apicall();
      await api
        .call('POST', 'api/task/check-task-delivery-content-has-valid-zip', { taskId: _taskid })
        .then((response) => {
          if (response.status !== 'OK') {
            self.error = response.msg;
          }
        });
    },
    confirmAbandonTask() {
      if (window.confirm('Are you sure you want to abandon this task?')) {
        const self = this;
        const api = new Apicall();
        api
          .call('POST', 'api/task/abandon-task', {
            taskid: this.tasktodisplayinfos.taskid,
          })
          .then((response) => {
            self.showtaskinfos = false;
            if (response.status === 'OK') {
              self.loadAvailableTasks();
              self.loadMyAssignedTasks();
            } else {
              self.error = response.msg;
            }
          });
      }
    },
    nl2br(str) {
      if (str) {
        return str.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2');
      }
      return '';
    },
  },
};
</script>

<style>
</style>
