<template>
    <mdb-tbl>
      <mdb-tbl-head>
        <tr>
          <th style="font-weight: bold">Id</th>
          <th style="font-weight: bold">Date ordered</th>
          <th style="font-weight: bold">Date completed</th>
          <th style="font-weight: bold">Task name</th>
          <th style="font-weight: bold">Task type</th>
          <th style="font-weight: bold">Date of payment</th>
          <th style="font-weight: bold">Payment</th>
          <th style="font-weight: bold">Payable</th>
        </tr>
      </mdb-tbl-head>
      <mdb-tbl-body class="main" v-if="(taskHistory instanceof Array)">
        <tr v-for="(task, index) in taskHistory" v-bind:key="index">
          <td>{{ task.taskid }}</td>
          <td>
            {{ task.dateadded }}
          </td>
          <td>
            {{ task.datecompleted }}
          </td>
          <td>
            {{ task.name }}
          </td>
          <td>
            {{ task.type }}
          </td>
          <td>
            {{ task.dateofpayment }}
          </td>
          <td>
            {{ task.payment }}
          </td>
          <td>
            <span style="color: lightgreen" v-if="task.isPayable && !task.dateofpayment">Payable</span>
            <span v-else-if="task.dateofpayment">Paid</span>
            <span v-else>Not Payable</span>
          </td>
        </tr>
      </mdb-tbl-body>
      <mdb-tbl-body v-else>
        <tr>
          <td colspan="7" class="text-center">
            <mdb-spinner  multicolor />
          </td>
        </tr>
      </mdb-tbl-body>
    </mdb-tbl>
</template>

<script>
import {
  mdbSpinner,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
} from 'mdbvue';

export default {
  components: {
    mdbSpinner,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
  },

  props: {
    taskHistory: null,
  },
};
</script>

<style>
</style>
