<template>
  <div>
    <mdb-breadcrumb class="mt-2 mb-0 blue lighten-4">
      <mdb-breadcrumb-item><a href="/admin">Admin</a></mdb-breadcrumb-item>
      <mdb-breadcrumb-item v-for="(item, index) in breadcrumb" v-bind:key="index">
        <a :href="item.href" v-if="item.href">{{item.title}}</a>
        <span v-else>{{item.title}}</span>
      </mdb-breadcrumb-item>
    </mdb-breadcrumb>
    <mdb-row>
      <mdb-navbar color="grey lighten-4" class="container-fluid mt-2 admintopbar" light>
        <mdb-navbar-toggler>
          <mdb-navbar-nav center class="justify-content-around nav-pills nav-fill">
            <mdb-nav-item anchorClass="black-text" class="font-weight-bold" to="/admin/employees">Employees</mdb-nav-item>
            <mdb-nav-item anchorClass="black-text" class="font-weight-bold" to="/admin/customers">Customers</mdb-nav-item>
            <mdb-nav-item anchorClass="black-text" class="font-weight-bold" to="/admin/tasks-configs">Tasks configs.</mdb-nav-item>
            <mdb-nav-item anchorClass="black-text" class="font-weight-bold" to="/admin/tasks">Tasks</mdb-nav-item>
            <mdb-nav-item anchorClass="black-text" class="font-weight-bold" to="/admin/tests">Tests</mdb-nav-item>
            <mdb-nav-item anchorClass="black-text" class="font-weight-bold" to="/admin/mailing-campaigns">Mailing Campaigns</mdb-nav-item>
            <mdb-nav-item anchorClass="black-text" class="font-weight-bold" to="/admin/ledgers">Ledger</mdb-nav-item>
          </mdb-navbar-nav>
        </mdb-navbar-toggler>
        <mdb-navbar-brand class="d-md-none">
          Menu
        </mdb-navbar-brand>
      </mdb-navbar>
    </mdb-row>
  </div>
</template>

<script>
import {
  mdbRow,
  mdbNavbar,
  mdbNavbarToggler,
  mdbNavbarNav,
  mdbNavItem,
  mdbNavbarBrand,
  mdbBreadcrumb,
  mdbBreadcrumbItem,
} from 'mdbvue';

export default {
  name: 'Admintopbar',
  data() {
    return {
      dummy: null,
    };
  },
  props: ['breadcrumb'],
  components: {
    mdbRow,
    mdbNavbar,
    mdbNavbarToggler,
    mdbNavbarNav,
    mdbNavItem,
    mdbNavbarBrand,
    mdbBreadcrumb,
    mdbBreadcrumbItem,
  },
  methods: {
    getbreadcrump() {
      return this.breadcrumb;
    },
  },
};
</script>

<style>
.admintopbar .nav-link.active{
  background-color: #03a9f4!important;
}
</style>
