<template>
  <div>
    <mdb-row v-show="isShowSearchBar===true">
      <mdb-col sm="12">
        <h3>Search ledger</h3>
        <form onsubmit="(event) => event.preventDefault()" @change="() => getLedgers()">
          <mdb-row>
            <mdb-col>
              <mdb-input v-model="from" type="date" label="From" /><br />
              <mdb-input class="mt-3" v-model="to" type="date" label="To" />
              <mdb-input class="mt-3" v-model="taskqueueId" type="text" label="Taskqueue ID" />
            </mdb-col>
            <mdb-col>
              <div>
                    <h4>
                      Project:
                    </h4>
                    <select
                      name="project_duplicate"
                      id="project_duplicate"
                      v-model="projectid"
                      class="browser-default custom-select"
                    >
                    <option value=""></option>
                    <option
                      v-for="(
                        projectItem, index
                      ) in projectList"
                      :key="index"
                      :value="projectItem.projectid"
                    >
                      {{ projectItem.name }} - {{ projectItem.client.email }}
                    </option>
                  </select>
              </div><br />
              <div>
                    <h4>
                      Customer:
                    </h4>
                    <select
                      name="customer_duplicate"
                      id="customer_duplicate"
                      v-model="userid"
                      class="browser-default custom-select"
                    >
                    <option value=""></option>
                    <option
                      v-for="(
                        customerItem, index
                      ) in customers"
                      :key="index"
                      :value="customerItem.id"
                    >
                      {{ customerItem.email }}
                    </option>
                  </select>
              </div>
            </mdb-col>
          </mdb-row>
        </form>
      </mdb-col>
    </mdb-row>
  <div>
    <button class="btn btn-primary" @click="handleOpenModal">Add Ledger</button>
    <mdb-alert color="primary" v-if="success">
      <p>{{'Ledger ID: ' + ledger.ledgerId}}</p>
      <p>{{ msg }}</p>
    </mdb-alert>
    <mdb-modal
      :show="showModal"
      @close="showModal = false"
      size="lg"
    >
    <mdb-modal-body class="mt-3 mr-5 ml-5">
      <form action="" v-on:submit="submitForm">
        <label for="userId">User ID</label>
          <select
            class="browser-default custom-select"
            id="userId"
            v-model="formUserId"
            @change="handleChange"
            required
            >
            <option v-for="customer in customers" :key="customer.id" :v-bind="customer.id" style="font-size: 15px;">{{`${customer.id}- ${customer.email}`}}</option>
          </select>
        <mdb-input label="Amount" type="text" v-model="amount" required placeholder="to subtract credits insert negative values e.g: -100"></mdb-input>
        <label for="taskqueueId">Taskqueue ID (optional)</label>
          <select
            class="browser-default custom-select"
            id="taskqueueId"
            v-model="formTaskqueueId"
            >
            <option v-for="taskqueue in taskqueues" :key="taskqueue.id" :v-bind="taskqueue.id" style="font-size: 15px;">{{`ID: ${taskqueue.id} - Taskqueue Billing: ${taskqueue.billing_unit_qty}`}}</option>
          </select>
        <div class="text-right">
          <button type="button" class="btn btn-secondary" @click="showModal = false">Cancel</button>
          <button type="submit" class="btn btn-primary">Submit</button>
        </div>
      </form>
    </mdb-modal-body>
    </mdb-modal>
  </div>
    <mdb-row>
      <mdb-col sm="12">
        <mdb-tbl class="table-responsive">
          <mdb-tbl-head>
            <tr>
              <th style="text-align: center;">ID</th>
              <th style="text-align: center;">Reason</th>
              <th style="text-align: center;">Amount</th>
              <th style="text-align: center;">Amount Units</th>
              <th style="text-align: center;">Charged Customer</th>
              <th style="text-align: center;">Taskqueue</th>
              <th style="text-align: center;">Time</th>
            </tr>
          </mdb-tbl-head>
          <mdb-tbl-body v-if="ledgers !== null && ledgers.length > 0">
            <tr v-for="(ledger, index) in ledgers" :key="index">
              <td>{{ledger.ledgerid}}</td>
              <td :class="{'title-max-width': !isShowSearchBar}">{{ledger.reason}}</td>
              <td style="text-align: end;">{{ledger.amount}}</td>
              <td style="text-align: center;">{{ledger.amount_units}}</td>
              <td :class="{'title-max-width': !isShowSearchBar}" style="text-align: center; text-overflow: ellipsis; width: 25%; line-height: 1.5rem;" v-if="ledger.user">
                <a @click="goToCustomerDetails(ledger.user.id)">{{ledger.user.email}} <mdb-icon icon="external-link-alt"/></a>
                <span v-if="ledger.taskqueue && ledger.user.id !== ledger.taskqueue.project.userid">
                  (<a @click="goToCustomerDetails(ledger.taskqueue.project.userid)">{{ledger.taskqueue.project.client.email}} <mdb-icon icon="external-link-alt"/></a>)
                </span>
              </td>
              <td v-else>
                --
              </td>
              <td v-if="ledger.taskqueue" style="text-align: center;">
                <mdb-btn color="primary" @click="goToTaskqueue(ledger.taskqueue.taskqueueid)" size="sm">
                  {{ ledger.taskqueue.queuename }}
                  <mdb-icon icon="external-link-alt" />
                </mdb-btn>
              </td>
              <td v-else style="text-align: center;">NONE</td>
              <td style="text-align: center;">{{ledger.added_at}}</td>
            </tr>
          </mdb-tbl-body>
          <mdb-tbl-body v-else-if="ledgers !== null">
            <tr>
              <td colspan="8" style="text-align: center;">No ledgers found</td>
            </tr>
          </mdb-tbl-body>
          <mdb-tbl-body v-else>
            <tr>
              <td colspan="8" style="text-align: center;">
                <mdb-spinner></mdb-spinner>
              </td>
            </tr>
          </mdb-tbl-body>
        </mdb-tbl>
      </mdb-col>
    </mdb-row>
  </div>

</template>

<script>
import {
  mdbRow, mdbCol, mdbInput, mdbTbl, mdbTblBody, mdbTblHead, mdbBtn, mdbIcon, mdbSpinner, mdbModal, mdbModalBody, mdbAlert,
} from 'mdbvue';
import Apicall from '@/libs/Apicall';
import moment from 'moment';

export default {
  components: {
    mdbBtn,
    mdbCol,
    mdbIcon,
    mdbInput,
    mdbRow,
    mdbSpinner,
    mdbTbl,
    mdbTblBody,
    mdbTblHead,
    mdbModal,
    mdbModalBody,
    mdbAlert,
  },
  props: {
    showSearchBar: {
      type: Boolean,
      required: false,
      default: true,
    },
    customerId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isShowSearchBar: this.showSearchBar,
      from: '',
      to: '',
      userid: null,
      projectid: null,
      taskqueueId: null,
      ledgers: null,
      projectList: [],
      customers: [],
      formUserId: null,
      formTaskqueueId: null,
      amount: null,
      showModal: false,
      taskqueues: [],
      success: false,
      ledger: null,
      msg: null,
    };
  },

  mounted() {
    const currentDate = new Date();

    this.from = moment().format('Y-MM-DD');
    this.to = moment().format('Y-MM-DD');
    if (this.isShowSearchBar === false) {
      this.from = '';
      this.userid = this.customerId;
    }
    this.getAllProjects();
    this.getAllCustomers();
    this.getLedgers();
  },

  methods: {
    getLedgers() {
      const apiCall = new Apicall();

      const body = {
        from: this.from,
        to: this.to,
        userid: this.userid,
        projectid: this.projectid,
        taskqueueid: this.taskqueueId,
      };

      this.ledgers = null;

      apiCall.call('POST', 'api/adminledger/get-all-ledger', body).then((response) => {
        if (response.status === 'OK') {
          this.ledgers = response.data.ledgerinfos;
          this.$emit('error', '');
        } else {
          this.$emit('error', response.msg);
        }
      });
    },

    getAllProjects() {
      const apiCall = new Apicall();

      apiCall.call('GET', 'api/project/get-all?include-disabled=true', null).then((response) => {
        if (response.status === 'OK') {
          this.projectList = response.data.projectinfos;
        } else {
          this.$emit('error', response.msg);
        }
      });
    },

    getAllCustomers() {
      const apiCall = new Apicall();

      apiCall.call('GET', 'api/admincustomer/list', null).then((response) => {
        if (response.status === 'OK') {
          this.customers = response.data;
        } else {
          this.$emit('error', response.msg);
        }
      });
    },

    goToCustomerDetails(id) {
      this.$router.push({ name: 'AdminCustomers', params: { customer: id } });
    },

    goToTaskqueue(taskqueueId) {
      window.open(`/admin/tasks/edit/${taskqueueId}`);
    },

    handleChange(event) {
      const [userId] = this.formUserId.split('-');
      this.taskqueues = [];
      this.getTaskqueuesByUserId(userId);
    },

    getTaskqueuesByUserId(userId) {
      const apiCall = new Apicall();
      apiCall.call('GET', `api/task/get-taskqueues-by-user-id?userId=${userId}`).then((response) => {
        if (response.status === 'OK') {
          this.taskqueues = response.data;
        } else {
          this.$emit('error', response.msg);
        }
      });
    },

    submitForm(event) {
      event.preventDefault();
      const apiCall = new Apicall();
      const [userId] = this.formUserId.split('-');
      const taskqueueId = this.formTaskqueueId !== null ? this.formTaskqueueId.split(' ')[1] : null;

      let body = {
        userId,
        amount: this.amount,
        taskqueueId,
      };

      apiCall.call('POST', 'api/adminledger/create-ledger', body).then((response) => {
        if (response.status === 'OK') {
          this.showModal = false;
          this.success = true;
          this.ledger = response.data.ledger;
          this.msg = response.msg;
          this.getLedgers();
        } else {
          this.showModal = false;
          this.success = false;
          body = {};
          this.$emit('error', response.msg);
        }
      });
      this.showModal = false;
    },

    handleOpenModal() {
      this.showModal = true;
      this.formUserId = null;
      this.amount = null;
      this.formTaskqueueId = null;
      this.success = false;
      this.ledger = null;
      this.msg = null;
    },
  },
};
</script>

<style scoped>
  a:hover {
    opacity: 50%;
  }
  .title-max-width {
    max-width: 170px;
  }
</style>
